import gsap from "gsap";
import React, { useCallback, useEffect, useState } from "react";
import LandingPageGatsbyImage from "./LandingPageGatsbyImage";
import random from "lodash/random";
import {
  LoadingAnimationStrip,
  LoadingAnimationWrapper,
} from "../styles/inspireMe";

const progressData = new Array(10).fill();

const AnimatedCards = ({ result, looks, containerWidth = 300, callback }) => {
  const [, setAnimation] = useState();
  const [currentProgress, setCurrentProgress] = useState(0);

  const [loadingBarColor, setLoadingBarColor] = useState("#fff");
  const [backgroundColor, setBackgroundColor] = useState("#fff");

  const animateStrip = useCallback(() => {
    let i = { value: 0 };
    const color = random(0, 255);
    setLoadingBarColor(`rgb(${color},${color},${color})`);
    gsap.to(i, {
      value: 10,
      duration: 2,
      ease: "steps(10)",
      onUpdate: (v) => {
        setCurrentProgress((prev) => {
          if (prev === i.value) return prev;

          if (i.value % 4) {
            const randomColor = random(30, 255);
            setBackgroundColor(
              `rgb(${randomColor},${randomColor},${randomColor})`
            );
          }
          return i.value;
        });
      },
      onUpdateParams: [i],
      onComplete: () => {
        setCurrentProgress(0);
        animateStrip();
      },
    });
  }, []);

  useEffect(() => {
    if (result) {
      setAnimation((prev) => {
        prev?.pause();
        return null;
      });
    }
  }, [result]);

  useEffect(() => {
    const cards = document.getElementsByClassName("animated_grid");

    gsap.set(cards, {
      zIndex: (i, _, targets) => {
        return targets.length + i;
      },
    });

    gsap.to(cards, {
      zIndex: (i, _, targets) => {
        return i !== 0 ? targets.length - i : targets.length;
      },
      stagger: {
        each: 0.2,
        from: "end",
      },
      onComplete: () => {
        callback();
      },
    });

    // setAnimation(animation);

    animateStrip();
  }, [animateStrip]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: backgroundColor,
        }}
      />
      {looks.map((look, idx) => {
        const { width, height } = look.looks[0].image.dimensions;
        const aspect = width / height;
        const randomScale = random(0.75, 1.5, true);
        const displayWidth = containerWidth * randomScale * aspect;
        const displayHeight = displayWidth / aspect;
        const left = window?.innerWidth * 0.5 - displayWidth * 0.5;
        const top = window?.innerHeight * 0.5 - displayHeight * 0.5;
        return (
          <div
            className="animated_grid"
            key={idx}
            style={{
              position: "fixed",
              left,
              top,
              width: displayWidth,
              height: displayHeight,
              zIndex: random(-50, 4),
            }}
          >
            <LandingPageGatsbyImage
              look={looks[idx]}
              aspectRatio={displayWidth / displayHeight}
              gatsbyImageData={look.looks[0].image.gatsbyImageData}
              style={{ height: "100%" }}
              disabled
            />
          </div>
        );
      })}
      <LoadingAnimationWrapper>
        {progressData.map((_, idx) =>
          idx < currentProgress ? (
            <LoadingAnimationStrip key={idx} color={loadingBarColor} />
          ) : null
        )}
      </LoadingAnimationWrapper>
    </>
  );
};
export default AnimatedCards;
