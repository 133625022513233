import * as React from "react";
import { graphql, navigate } from "gatsby";
import { useLanguage } from "../contexts/languageContext";
import { useMemo } from "react";
import {
  ActionWrapper,
  AnswerWrapper,
  ContentWrapper,
  FinalAnswerBlock,
  ImageContainer,
  KOLName,
  NextButton,
  PageContainer,
  PageWrapper,
  QuesitonOneGrid,
  QuestionButton,
  QuestionSlider,
  QuestionWrapper,
  SixGridQuestionButton,
  SixOptionGrid,
  SkipButton,
  SkipWrapper,
} from "../styles/inspireMe";
import GatsbyImageComponent from "../components/GatsbyImage";
import every from "lodash/every";
import random from "lodash/random";
import parse from "html-react-parser";
import { splitLines } from "../utils/processText";

import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import AnimatedCards from "../components/AnimatedCards";
import { getLooks } from "../utils/processData";
import { getIndividualRoute } from "../routes";

const timeout = 250;
const getTransitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};
const questions = ["look", "occasion", "attitude"];

const splitName = (text, strict) => {
  if (!strict) return text;
  if (text.split(" ").length > 2) {
    const split = text.split(" ");
    return (
      <>
        <div>{split[0]}</div>
        <div>{split.slice(1).join(" ")}</div>
      </>
    );
  }
  return text;
};
const QuestionOption = ({ question, copies, images, saveQuestion }) => {
  const [selected, setSelected] = React.useState();
  const { theme, isMobile } = useLanguage();
  switch (question) {
    case "look": {
      return (
        <QuesitonOneGrid>
          {copies.q1_options.map((item, idx) => (
            <QuestionButton
              key={idx}
              selected={`${question}_${idx}` === selected}
              onClick={() => {
                setSelected(`${question}_${idx}`);
                saveQuestion(question, idx === 0 ? "feminine" : "masculine");
              }}
              boxShadow={theme.cardBoxShadow}
            >
              <ImageContainer
                selected={`${question}_${idx}` === selected}
                boxShadow={theme.cardBoxShadow}
              >
                <GatsbyImageComponent
                  gatsbyImageData={item.image.gatsbyImageData}
                />
              </ImageContainer>

              <KOLName>{splitName(item.label, isMobile)}</KOLName>
            </QuestionButton>
          ))}
        </QuesitonOneGrid>
      );
    }
    case "occasion": {
      return (
        <SixOptionGrid>
          {[
            "night_out",
            "glamping",
            "gallery_hopping",
            "dinner_date",
            "everyday_errands",
            "9_to_5",
          ].map((q, idx) => (
            <SixGridQuestionButton
              key={q}
              selected={q === selected}
              onClick={() => {
                setSelected(q);
                saveQuestion(question, q);
              }}
              boxShadow={theme.cardBoxShadow}
            >
              <ImageContainer
                selected={q === selected}
                boxShadow={theme.cardBoxShadow}
              >
                <GatsbyImageComponent
                  gatsbyImageData={
                    images[`inspire_me_q2_${idx + 1}`].gatsbyImageData
                  }
                  style={{ height: "100%" }}
                />
              </ImageContainer>
            </SixGridQuestionButton>
          ))}
        </SixOptionGrid>
      );
    }
    case "attitude": {
      return (
        <SixOptionGrid>
          {[
            "carefree",
            "cheerful",
            "outgoing",
            "bold",
            "confident",
            "chill",
          ].map((q, idx) => (
            <SixGridQuestionButton
              key={q}
              selected={q === selected}
              onClick={() => {
                setSelected(q);
                saveQuestion(question, q);
              }}
              boxShadow={theme.cardBoxShadow}
            >
              <ImageContainer
                selected={q === selected}
                boxShadow={theme.cardBoxShadow}
              >
                <GatsbyImageComponent
                  gatsbyImageData={
                    images[`inspire_me_q3_${idx + 1}`].gatsbyImageData
                  }
                  style={{ height: "100%" }}
                />
              </ImageContainer>
            </SixGridQuestionButton>
          ))}
        </SixOptionGrid>
      );
    }
    default: {
      return null;
    }
  }
};
const InspireMePageTemplate = ({ data, pageContext }) => {
  const { lang, region, theme, setFilteredLooks } = useLanguage();
  const { copies, images, looks } = useMemo(() => {
    const nodes = data?.allPrismicInspireme?.nodes;

    return {
      copies: nodes?.find((h) => h.lang === lang)?.data,
      images: data?.prismicDataImages.data,
      looks: getLooks(data?.prismicDataLooksByRegion.data),
    };
  }, [data, lang]);
  const [finalResult, setFinalResult] = React.useState();

  const [activeStage, setActiveStage] = React.useState(questions[0]);

  const [filters, setFilters] = React.useState({
    look: null,
    occasion: null,
    attitude: null,
  });

  const filterIndividuals = React.useCallback(() => {
    const allLooks = looks ? looks : [];
    const allFilters = Object.keys(filters).reduce((a, c) => {
      if (!filters[c]) return a;
      return [...a, filters[c]];
    }, []);

    const filteredList = allLooks.filter((look) => {
      const tags = look.tags.map((t) => t.tag);
      if (!look.lookid) return false;
      if (!allFilters.length) return true;
      return every(allFilters, (p) => tags.includes(p));
    });
    return filteredList;
  }, [filters, looks]);

  React.useEffect(() => {
    if (activeStage === "result") {
      const randomResults = filterIndividuals();
      let displayResult = randomResults[random(0, randomResults.length - 1)];
      setFilteredLooks(randomResults);
      setFinalResult(displayResult);
    }
  }, [activeStage, filterIndividuals, region, setFilteredLooks]);

  const goToNext = React.useCallback(() => {
    if (activeStage === questions[2]) {
      setActiveStage("result");

      // resultRef.current.scrollIntoView({
      //   behavior: "smooth",
      // });
      return;
    }
    const index = questions.findIndex((q) => q === activeStage);
    // // const rect = refs[questions[index + 1]].current.getBoundingClientRect()
    // setTimeout(() => {
    //   refs[questions[index + 1]].current.scrollIntoView({
    //     behavior: "smooth",
    //   });
    // }, 0);
    setActiveStage((prev) => {
      return questions[index + 1];
    });
  }, [activeStage]);

  const saveQuestion = React.useCallback((category, answer) => {
    setFilters((prev) => ({
      ...prev,
      [category]: answer,
    }));
    // goToNext()
  }, []);

  const questionIndex = useMemo(
    () => questions.findIndex((q) => q === activeStage),
    [activeStage]
  );

  if (!copies) return null;

  return (
    <PageContainer color={theme.textColor}>
      <PageWrapper>
        <TransitionGroup component={QuestionSlider}>
          <ReactTransition
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
            key={activeStage}
          >
            {(status) =>
              activeStage !== "result" ? (
                <QuestionWrapper style={getTransitionStyles[status]}>
                  <ContentWrapper>
                    <h1>
                      {splitLines(copies[`q${questionIndex + 1}_label`]?.text)}
                    </h1>
                    <h2>{parse(copies[`q${questionIndex + 1}_cta`]?.text)}</h2>
                  </ContentWrapper>
                  <AnswerWrapper>
                    <QuestionOption
                      question={activeStage}
                      copies={copies}
                      images={images}
                      saveQuestion={saveQuestion}
                    />
                  </AnswerWrapper>
                </QuestionWrapper>
              ) : (
                <FinalAnswerBlock style={getTransitionStyles[status]}>
                  {finalResult && (
                    <AnimatedCards
                      looks={looks.slice(0, 8)}
                      result={finalResult}
                      callback={() => {
                        navigate(
                          `/${region}/${getIndividualRoute(finalResult.lookid)}`
                        );
                      }}
                    />
                  )}
                </FinalAnswerBlock>
              )
            }
          </ReactTransition>
        </TransitionGroup>
        {activeStage !== "result" && (
          <ActionWrapper>
            {/* {activeStage !== questions[0] && (
              <SkipWrapper left>
                <SkipButton onClick={goToPrev}>Back</SkipButton>
              </SkipWrapper>
            )} */}

            <NextButton
              onClick={goToNext}
              disabled={!filters[activeStage]}
              background={theme.textColor}
              color={theme.backgroundColor}
              disabledColor={theme.buttonDisabledColor}
              disabledBorderColor={theme.buttonDisabledBorderColor}
              disabledBackgroundColor={theme.buttonDisabledBackgroundColor}
            >
              {copies.action_next.text}
            </NextButton>
            <SkipWrapper>
              <SkipButton color={theme.textColor} onClick={goToNext}>
                {copies.action_skip.text}
              </SkipButton>
            </SkipWrapper>
          </ActionWrapper>
        )}
      </PageWrapper>
    </PageContainer>
  );
};

export const query = graphql`
  query pageQuery(
    $pageIds: [String]
    $generalCopiesIds: [String]
    $generalImagesId: String
    $regionLooksId: String
    $ecommerceLinkIds: [String]
  ) {
    allPrismicDataRegionEcommerce(filter: { id: { in: $ecommerceLinkIds } }) {
      nodes {
        id
        lang
        data {
          region
          links {
            label
            link
          }
        }
      }
    }
    allPrismicCopiesGeneral(filter: { id: { in: $generalCopiesIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          inspire_me
          about_the_jeans
          filter
          dark_mode
          light_mode
          meta: meta_inspire_me
        }
      }
    }
    prismicDataImages(id: { eq: $generalImagesId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        inspire_me_q2_1 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q2_2 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q2_3 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q2_4 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q2_5 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q2_6 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_1 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_2 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_3 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_4 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_5 {
          gatsbyImageData(width: 400)
        }
        inspire_me_q3_6 {
          gatsbyImageData(width: 400)
        }
      }
    }
    allPrismicInspireme(filter: { id: { in: $pageIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          q1_label {
            text
          }
          q1_cta {
            text
          }
          q2_label {
            text
          }
          q2_cta {
            text
          }
          q3_label {
            text
          }
          q3_cta {
            text
          }
          action_next {
            text
          }
          action_skip {
            text
          }
          result_label {
            text
          }
          result_button_label {
            text
          }
          q1_options {
            image {
              gatsbyImageData(width: 300)
            }
            label
          }
        }
      }
    }
    prismicDataLooksByRegion(id: { eq: $regionLooksId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        region
        spotify_playlist
        looks {
          look {
            document {
              __typename
              ... on PrismicLook {
                id
                data {
                  lookid
                  kol
                  attitude
                  looks {
                    image {
                      gatsbyImageData(width: 400)
                      url
                      dimensions {
                        height
                        width
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default InspireMePageTemplate;

export { default as Head } from "../components/Head";
