import styled from "styled-components";
import { BREAKPOINTS } from "../contexts/languageContext";
import { GeneralButton } from "./common";
import Container from "./Container";
import { mobileBreakpoint } from "./theme";

export const PageContainer = styled.div`
  margin-top: 24px;
  color: ${({ color }) => color};
`;
export const PageWrapper = styled(Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

const HalfHalfGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const QuestionWrapper = styled(HalfHalfGrid)`
  width: 100%;
  flex: 1;
  height: 100%;
  grid-gap: 48px;
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    grid-gap: 0px;
  }
`;

export const FinalAnswerBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const LoadingAnimationWrapper = styled.div`
  z-index: 20;
  position: fixed;
  bottom: 60px;
  display: flex;
  width: ${4 * 10 + 5 * 9}px;
  grid-gap: 5px;
`;
export const LoadingAnimationStrip = styled.div`
  width: 4px;
  height: 18px;
  background: ${({ color }) => color};
`;
export const QuestionSlider = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  flex: 1;
`;
export const QuestionHeader = styled.h1``;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ActionWrapper = styled(Container)`
  padding-bottom: 48px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  @media (max-width: ${mobileBreakpoint}) {
    padding-bottom: 24px;
  }
`;

export const SkipWrapper = styled.div`
  position: absolute;
  ${({ left }) => (left ? "left: 0" : "right: 0")};
`;
const BaseContent = styled.div`
  display: flex;
  align-items: center;
`;
export const ContentWrapper = styled(BaseContent)`
  flex-direction: column;
  justify-content: center;
  align-items: start;
  & h1 {
    margin: 0;
    text-align: left;
    font-size: min(64px, 5vw);
    line-height: min(64px, 5vw);
  }
  & h2 {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: ${BREAKPOINTS.desktop}px) {
    padding: 0 24px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    padding: 24px;
    & h1 {
      font-size: 30px;
      line-height: 30px;
    }
    & h2 {
      font-weight: 100;
      font-size: 18px;
    }
  }
`;

export const AnswerWrapper = styled(BaseContent)`
  justify-content: center;
`;
export const QuesitonOneGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0 36px;
  grid-template-areas:
    "a ."
    "a b"
    ". b";
  & > div:nth-of-type(1) {
    grid-area: a;
    transform: translateY(15%);
  }
  & > div:nth-of-type(2) {
    grid-area: b;
    transform: translateY(-15%);
  }
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: -36px;
    padding: 0 16px;
  }
`;

export const KOLName = styled.div`
  font-size: 30px;
  text-align: center;
  margin-top: 16px;
  text-decoration: none;
  text-underline-offset: 8px;
  line-height: 42px;
  font-weight: 600;
  border-bottom: 1px solid transparent;
  align-self: center;
  transition: 0.1s ease-in;
`;

export const ImageContainer = styled.div`
  transition: 0.1s ease-in;
  ${({ selected, boxShadow }) =>
    selected &&
    `
box-shadow: 0 0 20px 0 ${boxShadow};
`};
`;

export const QuestionButton = styled.div`
  cursor: pointer;
  transition: 0.1s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ selected }) =>
    selected &&
    `
    & ${KOLName} {
      text-decoration: underline;
    }
    `};
  &:hover {
    & ${ImageContainer} {
      box-shadow: 0 0 20px 0 ${({ boxShadow }) => boxShadow};
    }
    & ${KOLName} {
      text-decoration: underline;
    }
  }
`;
export const NextButton = styled(GeneralButton)`
  color: ${({ color }) => color};
  text-transform: uppercase;
  background: ${({ background }) => background};
  &:disabled {
    background: ${({ disabledColor }) => disabledColor};
    border-color: ${({ disabledBorderColor }) => disabledBorderColor};
    color: ${({ disabledBackgroundColor }) => disabledBackgroundColor};
  }
`;

export const SixOptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 16px;
  }
`;

export const SkipButton = styled(GeneralButton)`
  padding-left: 24px;
  border: 0;
  padding-right: 24px;
  color: ${({ color }) => color};
`;

export const SixGridQuestionButton = styled.div`
  cursor: pointer;
  transition: 0.1s ease-in;
  aspect-ratio: 0.75;
  max-height: 25vh;

  ${({ selected, boxShadow }) =>
    selected &&
    `
  & ${ImageContainer} {
    box-shadow: 0 0 20px 0 ${boxShadow};
  }

  `};
  &:hover {
    & ${ImageContainer} {
      box-shadow: 0 0 20px 0 ${({ boxShadow }) => boxShadow};
    }
  }
`;
